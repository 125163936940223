import '@fortawesome/fontawesome-free/css/all.min.css';
import './social.css';

function Social({ isGeek }) {
  return (
    <div className="social-container">
      {/* ----------------------------------------- Socials Starts ----------------------------------------- */}
      {!isGeek && (
        <a href="https://twitter.com/linkeshvaranr" target="_blank" rel="noopener noreferrer" className="social-icon">
          <i className="fa-brands fa-x-twitter"></i>
        </a>
      )}
      {!isGeek && (
        <a href="https://instagram.com/linkeshvaranr" target="_blank" rel="noopener noreferrer" className="social-icon">
          <i className="fab fa-instagram"></i>
        </a>
      )}
      {!isGeek && (
      <a href="https://www.linkedin.com/in/linkeshvaranr/" target="_blank" rel="noopener noreferrer" className="social-icon">
        <i className="fab fa-linkedin"></i>
      </a>
      )}
      {/* ----------------------------------------- Socials Ends ----------------------------------------- */}
      {/* ----------------------------------------- Geek Starts ----------------------------------------- */}
      {isGeek && (
      <a href="https://github.com/linkeshvaranr" target="_blank" rel="noopener noreferrer" className="social-icon">
        <i className="fab fa-github"></i>
      </a>
      )}
      {isGeek && (
        <a href="https://leetcode.com/u/linkeshvaranr/" target="_blank" rel="noopener noreferrer" className="social-icon">
          <i className="fa-solid fa-laptop-code"></i>
        </a>
      )}
      {isGeek && (
        <a href="https://www.hackerrank.com/profile/linkeshvaranr" target="_blank" rel="noopener noreferrer" className="social-icon">
          <i className="fa-brands fa-hackerrank"></i>
        </a>
      )}
      {isGeek && (
        <a href="https://www.salesforce.com/trailblazer/linkeshvaranr" target="_blank" rel="noopener noreferrer" className="social-icon">
          <i className="fa-brands fa-salesforce"></i>
        </a>
      )}
      {/* ----------------------------------------- Geek Ends ----------------------------------------- */}
    </div>
  );
}

export default Social;
