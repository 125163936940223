import React, { useState } from "react";
import "./App.css";
import LeeImageFront from "./Lee.png";
import LeeImageBack from "./Smile.png";
// import LeeImageBack2 from './Leelogo.png';
import Social from "./components/social/social";
import Name from "./components/name/name";
import Toggle from "./components/toggle/toggle";
// import Navbar from './components/navbar/navbar';

function App() {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn(!isOn);
  };

  const handleImageClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="lee-img-container" onClick={handleImageClick}>
          <img
            src={isFlipped ? LeeImageBack : LeeImageFront}
            alt="Linkeshvaran Raja Krishna Kumar"
            className="lee-img-style"
          />
        </div>
        <div className="social-container-main">

          <div className="icon-container-main">
            <Social isGeek={isOn} />
          </div>

          <div className="toggle-container-main">
            <Toggle isOn={isOn} toggle={handleToggle} />
          </div>

        </div>

        <Name />
      </header>
      

      {/* <Navbar /> */}
    </div>
  );
}

export default App;
