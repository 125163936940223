import React from 'react';
import './toggle.css';

const ToggleSwitch = ({ isOn, toggle }) => {
  return (
    <div className="checkbox-wrapper-35">
      <input
        value="private"
        name="switch"
        id="switch"
        type="checkbox"
        className="switch"
        checked={isOn}
        onChange={toggle}
      />
      <label htmlFor="switch">
        <span className="switch-x-text">Geek Mode </span>
        <span className="switch-x-toggletext">
          <span className="switch-x-unchecked">
            <span className="switch-x-hiddenlabel">Unchecked: </span>Off
          </span>
          <span className="switch-x-checked">
            <span className="switch-x-hiddenlabel">Checked: </span>On
          </span>
        </span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
