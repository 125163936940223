import React from 'react';
import './name.css';

function Name() {
    return (
        <div className="name-container">
            <span className="typewriter-text">Linkeshvaran Raja Krishna Kumar</span>
        </div>
    );
}

export default Name;
